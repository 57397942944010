<template>
  <div>
    <f-default-header :title="$t('pages.mkt.index.title')" :subtitle="$t('pages.mkt.index.subtitle')" />

    <div style="text-align: center">
      <v-btn elevation="0" color="primary" @click="go">{{ $t('labels.btnGoHome') }}</v-btn>
    </div>
  </div>
</template>

<script>
import env from "../../../env";
export default {
  methods: {
    go() {
      window.open(env.MKT_URL, "_blank");
    },
  },
};
</script>
